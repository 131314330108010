.tile {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 3rem 2rem 1rem 2rem;
	border-radius: 15px;
	cursor: pointer;
	position: relative;
	box-shadow: $box-shadow;

	background: #4f4fca;
	color: white;
	transition: 0.5s ease-in;

	&:hover {
		opacity: 0.7;
	}

	&-new-opportunity {
		@extend .tile;
		border: 1px dashed grey;
		background: white;
		color: black;
		padding: 2rem;
		i {
			font-size: 40px;
			margin-bottom: 2rem;
			color: grey;
			width: 2.5rem;
		}
	}

	.status {
		align-self: flex-end;
		position: absolute;
		top: 10px;
		right: 15px;
	}
}

.category-cards {
	margin-top: 1rem;
	cursor: pointer;
}

.card {
	border-radius: 15px !important;
}
