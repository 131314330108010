.application {
	&-list {
		&-row {
			cursor: pointer;

			// .profile {
			// 	img {
			// 		border-radius: 50%;
			// 		height: 5rem;
			// 	}
			// }
		}
	}
}

.file-container {
	flex-wrap: wrap;
	border: 1px solid #c6c6c6;
	border-radius: 0.75rem;
	padding: 0.5rem 1rem;
	.uploaded-file {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: column;
		text-align: center;
		max-width: 8rem;
		margin: 0.5rem 1rem 0.5rem 0;
		word-break: break-word;

		i {
			font-size: 30px;
			width: 5rem;
			padding-bottom: 0.5rem;
			color: grey;
		}
	}

	p {
		margin: 0;
	}

	.file-icon-container {
		cursor: pointer;
	}
}
