.status {
	width: 10rem;
	padding: 0.5rem 1rem;
	border-radius: 10px;
	color: white;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	max-height: 2.5rem;

	// font-weight: 200;

	&-approved {
		background: $green;
	}
	&-live {
		background: $green;
	}
	&-not-approved {
		background: $red;
	}
	&-pending {
		background: $warning;
	}
	&-pending-orange {
		background: $pending;
	}
}
