.text-readonly {
	font-weight: 600;
	color: #495057;
	white-space: pre-wrap;
}

ul {
	margin-left: 0;
	padding-left: 20px;
}

.helper-text {
	font-style: italic;
}

.text-pending {
	color: #ffc107 !important;
}
