.form-field {
	label {
		margin-bottom: 2px !important;
	}
}

.form-template-container {
	border: 1px solid $gray-300;
	border-radius: 10px;
	margin: -5px -10px;
	padding: 10px;
	box-shadow: $box-shadow;
}

.justify-content-between.date-start-end {
	@media screen and (min-width: 2000px) {
		justify-content: normal !important;
	}

	.form-field:first-child {
		margin-right: 1rem;
	}
}

.radio-group {
	padding-top: 0 !important;
}
