.badge {
	align-self: end !important;
	font-size: 14px !important;
	color: $black !important;

	&-not-approved {
		background: $red;
	}
	&-pending {
		background: $pending;
	}
}
