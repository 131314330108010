.close {
	position: absolute;
	top: 1rem;
	right: 1rem;
}

.atlas-theme {
	.sidebar {
		width: 9rem !important;

		@media only screen and (max-width: 566px) {
			width: 100% !important;
		}
	}

	.basic-layout {
		@media only screen and (max-width: 566px) {
			width: 100%;
		}
	}
}

.auto-complete .list-group {
	z-index: 100;
}

.text .required-validation {
	z-index: 20;
}
