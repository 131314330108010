.user-picture {
	img {
		// border-radius: 50%;
		border-top-left-radius: 50% 50%;
		border-top-right-radius: 50% 50%;
		border-bottom-right-radius: 50% 50%;
		border-bottom-left-radius: 50% 50%;
		height: 70px;
		width: 70px;
	}

	.skeleton-loader {
		max-height: 5rem;
	}
}
